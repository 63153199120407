<template>
  <div>
    <TableLoadingSkeleton v-if="loading" />
    <div v-else>
      <div class="d-flex flex-column gap-2 pt-4 pb-4">
        <p class="table-title">{{ $t('terms.supplierSettings.tabs.products') }} ({{ productsLength }})</p>
        <div class="d-flex gap-2">
          <div class="search-product">
            <el-input
              v-model="filtersAndSorts.productSkuSearch.searchInputValue"
              size="small"
              prefix-icon="el-icon-search"
              clearable
              :placeholder="$t('terms.supplierSettings.supplierProductsTable.search')"
              @clear="onProductsTextChange('')"
              @input="onProductsTextChange"
            />
          </div>

          <DropdownTableFilter
            trigger="click"
            clearable
            :filter-name="$t('terms.supplierSettings.supplierProductsTable.purchaseListFilter.label')"
            :filter-value="
              filtersAndSorts.purchaseListFilter.filterValue &&
              $t(
                `terms.supplierSettings.supplierProductsTable.purchaseListFilter.values.${filtersAndSorts.purchaseListFilter.filterValue}`
              )
            "
            :is-dark-theme="isPurchaseListFilterEnable"
            :is-value-weight-bold="true"
            @on-clear-filter="filtersAndSorts.purchaseListFilter.filterValue = ''"
            @on-choose-item="onOptionSelected"
          >
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="option in purchaseListFilterOptions"
                :key="option.value"
                :command="option.value"
                :class="{ 'dropdown-selected-item': filtersAndSorts.purchaseListFilter.filterValue === option.value }"
                @click="onOptionSelected(option.value)"
              >
                <div class="d-flex align-items-center gap-2">
                  <component :is="option.icon" :size="16" :class="option.value" />
                  <span>{{ option.label }}</span>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </DropdownTableFilter>
        </div>
      </div>
      <Table border rounded :data="table.data" :columns="columns" :show-index="(table.page - 1) * table.limit + 1">
        <template #[`cell-${SUPPLIER_PRODUCTS_COLUMNS.PURCHASE_LIST}`]="{ rowData }">
          <PurchaseListCell
            :row-data="rowData"
            @on-change-purchase-list-status="(choice) => onChangePurchaseListStatus(choice, rowData.id)"
          />
        </template>

        <template #cell-purchasesCount="{ rowData: { purchasesCount } }">
          <p>
            <span v-if="purchasesCount">{{ purchasesCount }}</span>
            <span v-else> - </span>
          </p>
        </template>

        <template #cell-lastOrderDate="{ rowData: { lastOrder, lastOrderDate } }">
          <p>
            <a v-if="lastOrder" href="" @click.prevent.stop="documentId = lastOrder.order.eventReferences[0].documentId"
              >{{ formatDate(lastOrderDate) }}
            </a>
            <template v-else>-</template>
          </p>
        </template>

        <template #cell-availability="{ rowData: { availability } }">
          <p>
            <span v-if="typeof availability === 'undefined'"> - </span>
            <el-tooltip
              v-if="!availability"
              :content="$t(`terms.supplierSettings.supplierProductsTable.unavailableProductTooltip`)"
            >
              <Tag type="warning">{{ $t(`catalog.products.table.availability.${availability}`) }}</Tag>
            </el-tooltip>
            <Tag v-else size="mini" type="success">{{ $t(`catalog.products.table.availability.${availability}`) }}</Tag>
          </p>
        </template>
      </Table>
    </div>
    <el-pagination
      v-if="!loading && table.allFilteredData.length > table.limit"
      small
      background
      :current-page.sync="table.page"
      class="my-2 float-right pb-4"
      layout="prev, pager, next, jumper"
      :page-size="table.limit"
      :page-count="Math.ceil(table.allFilteredData.length / table.limit)"
    />

    <ActionsModal
      :toggle-dialog="isDeleteModalOpen && productsToRemoveFromPurchaseList.length > 0"
      :title="$t('terms.supplierSettings.unavailableProductsModal.title')"
      :confirm-button-text="$t('terms.supplierSettings.unavailableProductsModal.removeFromPurchaseListBtn')"
      :confirm-button-loading="removeProductsFromPurchaseListLoading"
      :cancel-button-text="$t('terms.supplierSettings.unavailableProductsModal.cancelBtn')"
      :dialog-type="DIALOG_TYPES.DANGER"
      @on-cancel="onCloseRemoveProductsFromPurchaseList"
      @on-close="onCloseRemoveProductsFromPurchaseList"
      @on-confirm="onRemoveProductsFromPurchaseList"
    >
      <template #content>
        <div>
          <p class="mb-5">{{ $t('terms.supplierSettings.unavailableProductsModal.description') }}</p>
          <ul class="products-to-remove-list">
            <li v-for="product in productsToRemoveFromPurchaseList" :key="product.id">
              {{ product.name }}
            </li>
          </ul>
        </div>
      </template>
    </ActionsModal>
  </div>
</template>
<script>
import { computed, getCurrentInstance, reactive, ref, toRefs, watch } from 'vue';

import { ActionsModal, DIALOG_TYPES, Table, TableLoadingSkeleton, Tag } from '@/modules/core';
import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';

import PurchaseListCell, {
  getIsRecommendedByPurchaseListStatus,
} from '../../../components/supplierProductsPurchaseManagement/supplierTableCells/PurchaseListCell';
import {
  SUPPLIER_PRODUCTS_COLUMNS,
  getSupplierProductsColumns,
  getPurchaseListFilterOptions,
} from '../suppliersSettingsColumns';
import { sortProducts } from '@/modules/product/components/products/products-helpers';
import { debounce } from 'lodash';
import { useGroupOrderItemsByProduct } from '@/modules/product/compositions/order/useOrderItemsAggregationByProduct';
import { options } from '@/locale/dateConfig';
import { useTenancy } from '@/modules/auth';
import { useProductsNumberOfOrders } from '@/modules/product/compositions/useProductsNumberOfOrders';
import { DisabledIcon, ListCheckIcon, NeutralIcon } from '@/assets/icons';
import DropdownTableFilter from '@/modules/core/components/DropdownTableFilter.vue';
import { useUpdatePurchaseListItem } from '@/modules/purchase-management/compositions/purchaseList/useUpdatePurchaseListItem';
///TODO: fix the loading when just the pagination is visible.
export default {
  name: 'SupplierProductsFromCatalogTable',
  components: {
    Table,
    TableLoadingSkeleton,
    PurchaseListCell,
    Tag,
    DisabledIcon,
    NeutralIcon,
    ListCheckIcon,
    DropdownTableFilter,
    ActionsModal,
  },
  props: {
    loading: { type: Boolean, default: false },
    products: { type: Array, default: () => [] },
    purchaseListItems: { type: Array, default: () => [] },
    supplierId: { type: String, required: true },
  },
  emits: ['on-purchase-list-item-update'],
  setup(props, { emit }) {
    const { currentTenant } = useTenancy();
    const root = getCurrentInstance().proxy;
    const table = reactive({
      data: [],
      allFilteredData: [],
      page: 1,
      limit: 15,
    });

    const filtersAndSorts = reactive({
      productSkuSearch: {
        filterValue: '',
        searchInputValue: '',
      },
      purchaseListFilter: {
        filterValue: '',
      },
      activeSort: {
        direction: -1,
        columnKey: SUPPLIER_PRODUCTS_COLUMNS.PURCHASES_COUNT,
      },
    });
    const productsToRemoveFromPurchaseList = ref([]);
    const removeProductsFromPurchaseListLoading = ref(false);
    const hasModalBeenShown = ref(false);
    const productsLength = ref(0);
    const { products, purchaseListItems } = toRefs(props);
    const updatedPurchaseListItems = ref(purchaseListItems.value || []);
    const isDeleteModalOpen = ref(false);
    const { updatePurchaseListItem } = useUpdatePurchaseListItem();

    watch(
      () => purchaseListItems.value,
      () => {
        updatedPurchaseListItems.value = purchaseListItems.value;
      }
    );

    watch(
      [() => props.loading, productsToRemoveFromPurchaseList],
      ([loading, updatedProductsToRemove]) => {
        if (!loading && !hasModalBeenShown.value && updatedProductsToRemove.length > 0) {
          isDeleteModalOpen.value = true;
          hasModalBeenShown.value = true;
        }
      },
      { deep: true }
    );

    const { productsNumberOfOrders } = useProductsNumberOfOrders(
      computed(() => ({ tenantId: currentTenant.value.id, supplierId: props.supplierId }))
    );

    const formatDate = (ms) => new Date(ms).toLocaleDateString(root.$i18n.locale, options.short);

    const productIds = computed(() => products.value.map(({ id }) => id));

    const { groupOrderItemsByProduct: lastOrders } = useGroupOrderItemsByProduct(
      computed(() => ({ productIds: productIds.value, businessId: currentTenant.value.id }))
    );

    const columns = computed(() => getSupplierProductsColumns(filtersAndSorts.activeSort));

    watch(
      [
        products,
        filtersAndSorts,
        lastOrders,
        productsNumberOfOrders,
        updatedPurchaseListItems,
        () => table.page,
        () => table.limit,
      ],
      () => {
        let dataToReturn = [];

        for (const product of products.value) {
          const purchaseListItem = updatedPurchaseListItems.value.find(({ productId }) => productId === product.id);
          const isRecommendedPurchaseListItem = purchaseListItem ? purchaseListItem.isRecommended : null;
          if (isRecommendedPurchaseListItem || product.availability) {
            dataToReturn.push({
              id: product.id,
              name: product.name ?? MISSING_DATA_TEXT,
              sku: product.sku ?? MISSING_DATA_TEXT,
              isRecommended: isRecommendedPurchaseListItem,
              availability: product.availability,
            });
          }
        }

        productsLength.value = dataToReturn.length;

        productsToRemoveFromPurchaseList.value = dataToReturn.filter(
          (product) => product.availability === false && product.isRecommended === true
        );

        let filteredData = dataToReturn.filter((product) => {
          let filterIn = true;

          if (filtersAndSorts.productSkuSearch.filterValue) {
            table.page = 1;
            const productNameSearchTerms = filtersAndSorts.productSkuSearch.filterValue
              .trim()
              .toLowerCase()
              .split(/\s+/);

            filterIn = productNameSearchTerms.every((term) => product.name.toLowerCase().includes(term));
          }

          if (filtersAndSorts.purchaseListFilter.filterValue) {
            table.page = 1;
            filterIn =
              filterIn &&
              product.isRecommended ===
                getIsRecommendedByPurchaseListStatus(filtersAndSorts.purchaseListFilter.filterValue);
          }

          return filterIn;
        });

        filteredData = filteredData.map((product) => {
          const lastOrder = lastOrders.value?.find((lastOrder) => lastOrder.productId === product.id)?.lastOrder;
          const purchasesCount = productsNumberOfOrders.value.find(
            (productNumberOfOrders) => productNumberOfOrders.productId === product.id
          )?.numberOfOrders;

          return {
            ...product,
            lastOrderDate: lastOrder?.date,
            lastOrder,
            purchasesCount,
          };
        });

        if (filtersAndSorts.activeSort.columnKey) {
          filteredData = sortProducts(
            filteredData,
            filtersAndSorts.activeSort.columnKey,
            filtersAndSorts.activeSort.direction
          );
        }

        table.allFilteredData = [...filteredData];
        const paginatedData = filteredData.slice(table.limit * (table.page - 1), table.limit * table.page);
        table.data = paginatedData;
      },
      { deep: true, immediate: true }
    );

    const onChangePurchaseListStatus = (choice, productId) => {
      const isRecommended = getIsRecommendedByPurchaseListStatus(choice);

      const foundItem = updatedPurchaseListItems.value.find((item) => item.productId === productId);

      if (foundItem) {
        updatedPurchaseListItems.value = updatedPurchaseListItems.value.map((purchaseListItem) => {
          if (purchaseListItem.productId === productId) {
            return {
              ...purchaseListItem,
              isRecommended,
            };
          }
          return purchaseListItem;
        });
      } else {
        updatedPurchaseListItems.value.push({
          isRecommended,
          productId,
        });
      }

      emit('on-purchase-list-item-update', { isRecommended, productId });
    };

    const onProductsTextChange = debounce((value) => {
      filtersAndSorts.productSkuSearch.filterValue = value;
      emit('on-product-search', value);
    }, 700);

    const isPurchaseListFilterEnable = computed(() => !!filtersAndSorts.purchaseListFilter.filterValue);

    const onOptionSelected = (value) => {
      filtersAndSorts.purchaseListFilter.filterValue = value;
    };

    const purchaseListFilterOptions = getPurchaseListFilterOptions();

    const onRemoveProductsFromPurchaseList = async () => {
      removeProductsFromPurchaseListLoading.value = true;

      try {
        const promises = productsToRemoveFromPurchaseList.value.map((product) =>
          updatePurchaseListItem({
            isRecommended: false,
            productId: product.id,
            businessId: currentTenant.value.id,
            supplierId: props.supplierId,
          })
        );

        for (let i = 0; i < promises.length; i += 15) {
          const chunk = promises.slice(i, i + 15);
          await Promise.all(chunk);
        }

        updatedPurchaseListItems.value = updatedPurchaseListItems.value.map((purchaseListItem) => {
          if (productsToRemoveFromPurchaseList.value.some((product) => product.id === purchaseListItem.productId)) {
            return {
              ...purchaseListItem,
              isRecommended: false,
            };
          }
          return purchaseListItem;
        });
      } catch (error) {
        console.error('error while deleting product from catalog', error);
      } finally {
        removeProductsFromPurchaseListLoading.value = false;
        onCloseRemoveProductsFromPurchaseList();
      }

      root.$message.warning(
        root.$i18n.t('terms.supplierSettings.unavailableProductsModal.successMessage', {
          productsLength: productsToRemoveFromPurchaseList.value.length,
        })
      );

      productsToRemoveFromPurchaseList.value = [];
    };

    const onCloseRemoveProductsFromPurchaseList = () => {
      isDeleteModalOpen.value = false;
    };

    return {
      SUPPLIER_PRODUCTS_COLUMNS,
      columns,
      onChangePurchaseListStatus,
      table,
      filtersAndSorts,
      onProductsTextChange,
      formatDate,
      purchaseListFilterOptions,
      isPurchaseListFilterEnable,
      onOptionSelected,
      productsToRemoveFromPurchaseList,
      isDeleteModalOpen,
      DIALOG_TYPES,
      onRemoveProductsFromPurchaseList,
      onCloseRemoveProductsFromPurchaseList,
      removeProductsFromPurchaseListLoading,
      productsLength,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.table-title {
  font-size: 1rem;
  font-weight: 700;
}
.search-product {
  height: 2rem;
  width: 14rem;
}
.products-searchbar {
  width: 16rem !important;
}
.no-select-display .el-select__tags {
  display: none;
}
.dropdown-selected-item {
  color: #3661dd;
  background-color: #f5f7fa;
}
.approved {
  color: #11874f;
}
.disabledStatus {
  color: #e52044;
}
.neutral {
  color: $typography-secondary;
}
.products-to-remove-list {
  list-style-type: disc;
  font-family: Rubik;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  max-height: 110px;
  overflow-y: auto;
}
</style>
