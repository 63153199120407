import i18n from '@/imports/startup/client/i18n';

export const SUPPLIER_COLUMNS = {
  SUPPLIER_NAME: 'supplierName',
  PRODUCTS_IN_PURCHASE_BASKET: 'productsInPurchaseBasket',
  DELIVERY_TIMES: 'deliveryTimes',
};

export const SUPPLIER_PRODUCTS_COLUMNS = {
  NAME: 'name',
  SKU: 'sku',
  PURCHASES_COUNT: 'purchasesCount',
  LAST_ORDER_DATE: 'lastOrderDate',
  AVAILABILITY: 'availability',
  PURCHASE_LIST: 'purchaseList',
};

export const PURCHASE_LIST_FILTER_OPTIONS = {
  NEUTRAL: 'neutral',
  APPROVED: 'approved',
  DISABLED: 'disabledStatus',
};

export const getTranslationForSupplierTable = (key) => i18n.t(`terms.suppliersSettings.supplierTable.headers.${key}`);
export const getTranslationForSupplierProductsTable = (key) =>
  i18n.t(`terms.supplierSettings.supplierProductsTable.headers.${key}`);
export const getTranslationForPurchaseListFilter = (key) =>
  i18n.t(`terms.supplierSettings.supplierProductsTable.purchaseListFilter.values.${key}`);

export const getSupplierColumns = () => [
  {
    header: getTranslationForSupplierTable(SUPPLIER_COLUMNS.SUPPLIER_NAME),
    key: SUPPLIER_COLUMNS.SUPPLIER_NAME,
    width: '400px',
  },
  {
    header: getTranslationForSupplierTable(SUPPLIER_COLUMNS.PRODUCTS_IN_PURCHASE_BASKET),
    key: SUPPLIER_COLUMNS.PRODUCTS_IN_PURCHASE_BASKET,
    width: '228px',
  },
  {
    header: getTranslationForSupplierTable(SUPPLIER_COLUMNS.DELIVERY_TIMES),
    key: SUPPLIER_COLUMNS.DELIVERY_TIMES,
    width: '228px',
  },
];

export const getSupplierProductsColumns = (activeSort) => [
  {
    header: getTranslationForSupplierProductsTable(SUPPLIER_PRODUCTS_COLUMNS.NAME),
    key: SUPPLIER_PRODUCTS_COLUMNS.NAME,
    width: '240px',
    sortable: true,
    sortCallback: (direction) => {
      activeSort.direction = direction;
      activeSort.columnKey = SUPPLIER_PRODUCTS_COLUMNS.NAME;
    },
  },
  {
    header: getTranslationForSupplierProductsTable(SUPPLIER_PRODUCTS_COLUMNS.SKU),
    key: SUPPLIER_PRODUCTS_COLUMNS.SKU,
    width: '200px',
  },
  {
    header: getTranslationForSupplierProductsTable(SUPPLIER_PRODUCTS_COLUMNS.PURCHASES_COUNT),
    key: SUPPLIER_PRODUCTS_COLUMNS.PURCHASES_COUNT,
    width: '10rem',
    sortable: true,
    sortCallback: (direction) => {
      activeSort.direction = direction;
      activeSort.columnKey = SUPPLIER_PRODUCTS_COLUMNS.PURCHASES_COUNT;
    },
  },
  {
    header: getTranslationForSupplierProductsTable(SUPPLIER_PRODUCTS_COLUMNS.LAST_ORDER_DATE),
    key: SUPPLIER_PRODUCTS_COLUMNS.LAST_ORDER_DATE,
    width: '10rem',
    sortable: true,
    sortCallback: (direction) => {
      activeSort.direction = direction;
      activeSort.columnKey = SUPPLIER_PRODUCTS_COLUMNS.LAST_ORDER_DATE;
    },
  },
  {
    header: getTranslationForSupplierProductsTable(SUPPLIER_PRODUCTS_COLUMNS.AVAILABILITY),
    key: SUPPLIER_PRODUCTS_COLUMNS.AVAILABILITY,
    width: '10rem',
    sortable: true,
    sortCallback: (direction) => {
      activeSort.direction = direction;
      activeSort.columnKey = SUPPLIER_PRODUCTS_COLUMNS.AVAILABILITY;
    },
  },
  {
    header: getTranslationForSupplierProductsTable(SUPPLIER_PRODUCTS_COLUMNS.PURCHASE_LIST),
    key: SUPPLIER_PRODUCTS_COLUMNS.PURCHASE_LIST,
    width: '228px',
    sortable: true,
    sortCallback: (direction) => {
      activeSort.direction = direction;
      activeSort.columnKey = 'isRecommended';
    },
  },
];

export const getPurchaseListFilterOptions = () => [
  {
    value: PURCHASE_LIST_FILTER_OPTIONS.NEUTRAL,
    label: getTranslationForPurchaseListFilter(PURCHASE_LIST_FILTER_OPTIONS.NEUTRAL),
    icon: 'NeutralIcon',
  },
  {
    value: PURCHASE_LIST_FILTER_OPTIONS.APPROVED,
    label: getTranslationForPurchaseListFilter(PURCHASE_LIST_FILTER_OPTIONS.APPROVED),
    icon: 'ListCheckIcon',
  },
  {
    value: PURCHASE_LIST_FILTER_OPTIONS.DISABLED,
    label: getTranslationForPurchaseListFilter(PURCHASE_LIST_FILTER_OPTIONS.DISABLED),
    icon: 'DisabledIcon',
  },
];
