import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useAgreements({ currentTenant, first, after }) {
  const { error } = useNotification();

  const variables = computed(() => ({
    customerId: currentTenant.value.id,
    first,
    after: after ? after.value : null,
  }));

  const { result, loading, onError, refetch, onResult } = useQuery(AGREEMENTS_QUERY, variables, () => ({
    enabled: !!variables.value.customerId,
  }));

  const agreements = computed(() => result.value?.agreementsNew?.nodes ?? []);
  const totalCount = computed(() => result.value?.agreementsNew?.totalCount ?? 0);

  onError((err) => {
    console.error('useAgreements', err);
    error();
  });

  return {
    agreements,
    totalCount,
    loading,
    refetch,
    onResult,
  };
}

const AGREEMENTS_QUERY = gql`
  query agreementsNew($customerId: ID!, $first: Int, $after: Int) {
    agreementsNew(customerId: $customerId, first: $first, after: $after) {
      nodes {
        id
        supplier {
          id
          name
        }
      }
      totalCount
    }
  }
`;
