import { gql } from '@apollo/client/core';
import { getCurrentInstance } from 'vue';
import { useMutation } from '@vue/apollo-composable';

import { useTranslate } from '@/modules/core/compositions/translate';
import { useNotification } from '@/modules/core/compositions/notification';

export const useCreateAgreement = () => {
  const { error } = useNotification();
  const { $message } = getCurrentInstance().proxy;
  const $t = useTranslate();

  const { mutate, loading, onError, onDone } = useMutation(CREATE_AGREEMENT);

  onError((err) => {
    if (err?.graphQLErrors[0]?.extensions?.response?.status === 409) {
      $message.error($t('terms.suppliersSettings.addSupplierModal.exists'));
    } else {
      console.error('useCreateAgreement', err);
      error();
    }
  });

  return {
    createAgreement: mutate,
    loading,
    onDone,
  };
};

const CREATE_AGREEMENT = gql`
  mutation agreementNewCreate($agreementCreateInput: AgreementNewCreateInput) {
    agreementNewCreate(agreementCreateInput: $agreementCreateInput) {
      id
    }
  }
`;
