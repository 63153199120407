import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useOrderTermsForSuppliers({ currentTenant, first, after }) {
  const { error } = useNotification();

  const variables = computed(() => ({
    businessId: currentTenant.value.id,
    first,
    after: after ? after.value : null,
  }));

  const { result, loading, onError, refetch, onResult } = useQuery(ORDER_TERMS, variables, () => ({
    enabled: !!variables.value.businessId,
  }));

  const orderTerms = computed(() => result.value?.orderTerms ?? []);
  const totalCount = computed(() => result.value?.totalCount ?? 0);

  onError((err) => {
    console.error('useOrderTermsForSuppliers', err);
    error();
  });

  return {
    orderTerms,
    totalCount,
    loading,
    refetch,
    onResult,
  };
}

const ORDER_TERMS = gql`
  query orderTerms($businessId: ID!, $first: Int, $after: Int) {
    orderTerms(businessId: $businessId, first: $first, after: $after) {
      totalCount
      nodes {
        supplierId
        schedules {
          deliveryArrivalTime {
            dayOfWeek
            timeOfDay
          }
        }
      }
    }
  }
`;
