import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useRecommendedPurchaseListItems({ currentTenant, first, after }) {
  const { error } = useNotification();
  const variables = computed(() => ({
    businessId: currentTenant.value.id,
    isRecommended: true,
    first,
    after: after ? after.value : null,
  }));

  const { result, loading, onError, refetch, onResult } = useQuery(PURCHASE_LIST_ITEMS_QUERY, variables, () => ({
    enabled: !!variables.value.businessId,
  }));

  const purchaseListItems = computed(() => result.value?.purchaseListItems ?? []);
  const totalCount = computed(() => result.value?.totalCount ?? 0);

  onError((err) => {
    console.error('useRecommendedPurchaseListItems', err);
    error();
  });

  return {
    totalCount,
    purchaseListItems,
    loading,
    refetch,
    onResult,
  };
}

const PURCHASE_LIST_ITEMS_QUERY = gql`
  query recommendedPurchaseListItems(
    $businessId: ID!
    $supplierId: ID
    $isRecommended: Boolean
    $first: Int
    $after: Int
  ) {
    purchaseListItems(
      businessId: $businessId
      supplierId: $supplierId
      isRecommended: $isRecommended
      first: $first
      after: $after
    ) {
      nodes {
        supplierId
      }
      totalCount
    }
  }
`;
