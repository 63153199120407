<template>
  <div>
    <TableLoadingSkeleton v-if="loading" />
    <Table
      v-else
      border
      rounded
      :data="data"
      :columns="columns"
      :show-index="(page - 1) * PAGE_LIMIT + 1"
      @row-click="onRowClick"
    />
    <el-pagination
      class="my-2 float-right pb-4"
      layout="prev, pager, next, jumper"
      small
      hide-on-single-page
      background
      :page-size="PAGE_LIMIT"
      :total="total"
      :page-count="Math.ceil(total / PAGE_LIMIT)"
      @current-change="onPageChanged"
    />
  </div>
</template>
<script>
import { ref, computed, getCurrentInstance } from 'vue';

import { Table, TableLoadingSkeleton } from '@/modules/core';
import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';

import { getSupplierColumns } from '../suppliersSettingsColumns';
import { getDateFromSupplierSchedule } from '../../../purchaseManagement';
import { formatDay } from '../../../purchaseManagementFormatters';

export default {
  name: 'SuppliersTable',
  components: { Table, TableLoadingSkeleton },
  props: {
    total: { type: Number, default: 0 },
    loading: { type: Boolean, default: false },
    agreements: { type: Array, default: () => [] },
    supplierSearch: { type: String, default: null },
    orderTerms: { type: Array, default: () => [] },
    purchaseListItems: { type: Array, default: () => [] },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const page = ref(1);
    const PAGE_LIMIT = 25;

    const columns = getSupplierColumns();

    const purchaseListCountMap = computed(() => {
      const supplierMap = {};
      for (const purchaseList of props.purchaseListItems) {
        supplierMap[purchaseList.supplierId] =
          supplierMap[purchaseList.supplierId] === undefined ? 1 : supplierMap[purchaseList.supplierId] + 1;
      }

      return supplierMap;
    });

    const orderTermMap = computed(() => {
      const supplierMap = {};
      for (const orderTerm of props.orderTerms) {
        const days = new Set();
        for (const schedule of orderTerm.schedules) {
          const date = getDateFromSupplierSchedule(
            schedule.deliveryArrivalTime.dayOfWeek,
            schedule.deliveryArrivalTime.timeOfDay
          );
          days.add(formatDay(date));
        }
        supplierMap[orderTerm.supplierId] = [...days].join(', ');
      }

      return supplierMap;
    });
    const data = computed(() => {
      const dataToReturn = [];
      for (const agreement of props.agreements) {
        const supplierId = agreement.supplier?.id;
        const rowData = {
          supplierId,
          supplierName: agreement.supplier?.name,
          productsInPurchaseBasket: purchaseListCountMap.value[supplierId] ?? 0,
          deliveryTimes: orderTermMap.value[supplierId] ?? MISSING_DATA_TEXT,
        };
        if (props.supplierSearch !== null && props.supplierSearch !== undefined && props.supplierSearch !== '') {
          if (agreement.supplier?.name.includes(props.supplierSearch)) {
            dataToReturn.push(rowData);
          }
        } else {
          dataToReturn.push(rowData);
        }
      }

      return dataToReturn
        .sort((a, b) => a.supplierName.localeCompare(b.supplierName))
        .slice((page.value - 1) * PAGE_LIMIT, page.value * PAGE_LIMIT);
    });

    const onPageChanged = (newPage) => {
      page.value = newPage;
    };

    const onRowClick = (index) => {
      root.$router.push({
        name: 'supplierSettings',
        params: { supplierId: data.value[index].supplierId },
      });
    };

    return {
      PAGE_LIMIT,
      page,
      data,
      columns,
      onRowClick,
      onPageChanged,
    };
  },
};
</script>
<style lang="scss" scoped></style>
