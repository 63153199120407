<template>
  <Modal
    :visible="visible"
    width="37.5rem"
    :title="$t('terms.suppliersSettings.addSupplier')"
    :submit-text="$t('terms.suppliersSettings.addSupplierModal.add')"
    :submit-loading="createLoading"
    :submit-disabled="!selectedSupplier"
    @on-close="onCloseModal"
    @on-submit="submitAgreement"
  >
    <div class="d-flex flex-column gap-2">
      <p class="choose-supplier">{{ $t('terms.suppliersSettings.addSupplierModal.chooseSupplier') }}</p>
      <el-select
        :value="selectedSupplier?.name"
        size="small"
        :loading="searchAndSupplierLoading"
        :placeholder="$t('select')"
        :style="{ width: '25rem' }"
        filterable
        :filter-method="handleSearchInput"
        @change="handleSupplierSelected"
      >
        <el-option
          v-for="supplierOption in suppliers"
          :key="supplierOption.id"
          :label="supplierOption.name"
          :value="supplierOption.id"
        />
      </el-select>
    </div>
  </Modal>
</template>
<script>
import { ref, watch, computed, getCurrentInstance } from 'vue';

import { useTenancy } from '@/modules/auth';

import Modal from '../../../../catalog/components/Modal.vue';
import { useBusinessesForCatalog } from '../../../../catalog/compositions/useBusinessesForCatalog';
import { useCreateAgreement } from '../compositions/useCreateAgreement';

const BUSINESSES_LIMIT = 100;
export default {
  name: 'AddSupplierModal',
  components: { Modal },
  props: {
    visible: { type: Boolean, default: false },
  },
  emits: ['on-close', 'on-added-supplier'],
  setup(_, { emit }) {
    const root = getCurrentInstance().proxy;
    const searchSupplier = ref();
    const selectedSupplier = ref();
    const enteredSearch = ref(false);

    const { currentTenant } = useTenancy();

    const { createAgreement, onDone, loading: createLoading } = useCreateAgreement();

    const {
      businesses: suppliers,
      loading: suppliersLoading,
      clearBusinesses,
    } = useBusinessesForCatalog(
      computed(() => ({
        ...(searchSupplier.value !== null && searchSupplier.value !== '' && { search: searchSupplier.value }),
        capabilities: {
          supplier: true,
        },
        first: BUSINESSES_LIMIT,
        after: 0,
      })),
      true
    );
    const searchAndSupplierLoading = computed(() => suppliersLoading.value || enteredSearch.value);

    watch(suppliersLoading, () => {
      if (suppliersLoading.value) {
        if (enteredSearch.value) {
          enteredSearch.value = false;
        }
      }
    });

    const submitAgreement = () => {
      createAgreement({
        agreementCreateInput: {
          customerId: currentTenant.value?.id,
          supplierId: selectedSupplier.value?.id,
        },
      });
    };

    onDone(() => {
      root.$message.success(root.$t('terms.suppliersSettings.addSupplierModal.success'));
      emit('on-added-supplier', { supplierId: selectedSupplier.value?.id });
    });

    const onCloseModal = () => {
      emit('on-close');
    };

    const handleSearchInput = (newValue) => {
      clearBusinesses();
      searchSupplier.value = newValue;
      enteredSearch.value = true;
    };

    const handleSupplierSelected = (newSupplierId) => {
      selectedSupplier.value = suppliers.value.find(({ id }) => newSupplierId === id);
    };

    return {
      suppliers,
      createLoading,
      selectedSupplier,
      searchAndSupplierLoading,
      onCloseModal,
      submitAgreement,
      handleSearchInput,
      handleSupplierSelected,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.choose-supplier {
  font-weight: 500;
  color: $typography-primary;
}
</style>
